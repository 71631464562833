<template>
  <div class="mt-16">
    <v-row justify="center" v-if="loading" class="pt-6">
      <v-col cols="1">
        <Spinner />
      </v-col>
    </v-row>
    <v-container v-else>
      <v-row>
        <v-col v-html="content"></v-col>
      </v-row>
      <v-row>
        <v-col align="center" justify="center">
          <v-btn outlined @click="setProceedFlag">{{ $t('proceed') | uppercase }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import axios from 'axios';
  import store from '@/store';
  import { captureException } from '@/utils/sentry';

  export default {
    mounted() {
      this.readDataFromAPI();
    },
    computed: {
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
      baseUrl() {
        return store.get('misc/baseUrl');
      },
    },
    data: () => ({
      pageId: 2248,
      content: '',
      loading: true,
    }),
    methods: {
      setProceedFlag() {
        store.set('misc/proceed', true);
        this.$router.push({ name: 'Shop' }).catch((error) => {
          console.info(error.message);
        });
      },
      readDataFromAPI() {
        const pageReq = axios.get(`${this.baseUrl}/wp/v2/pages/${this.pageId}`, {
          headers: this.authHeader,
        });

        axios
          .all([pageReq])
          .then(
            axios.spread((...responses) => {
              this.loading = false;
              this.content = responses[0].data.content.rendered;
            })
          )
          .catch((errors) => {
            captureException(errors);
          });
      },
    },
    components: {
      Spinner: () => import('@/views/components/mini-components/Spinner'),
    },
  };
</script>
<style>
  .v-text-field__details,
  .v-messages {
    min-height: 0;
  }
</style>
