import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from './App';
import router from '@/router';
import i18n from '@/i18n';
import store from '@/store';
import Dinero from 'dinero.js';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false;

Vue.filter('uppercase', function (value) {
  if (!value) return '';
  return value.toUpperCase();
});
Vue.filter('camelcase', function (value) {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('capitalize', function (value) {
  if (!value) return '';
  return value
    .split(/ /g)
    .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
    .join(' ');
});
Vue.filter('toPrice', function (value) {
  return Dinero({ amount: parseInt(value * 100, 10), currency: 'EUR' });
});
Vue.filter('formatPrice', function (v) {
  try {
    return v.setLocale('it-IT').toFormat().replace(',', '.');
  } catch (e) {
    if (typeof v === 'object' && Object.keys(v).includes('amount')) {
      return new Dinero(v).setLocale('it-IT').toFormat().replace(',', '.');
    } else {
      return v + ' €';
    }
  }
});

const dsn = process.env.VUE_APP_SENTRY_DSN;
if (dsn) {
  Sentry.init({
    Vue: Vue,
    dsn: dsn,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  vuetify,
  store,
  i18n,
  components: { App },
  render: (h) => h(App),
}).$mount('#app');
