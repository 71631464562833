<template>
  <div class="mt-16">
    <v-container>
      <p class="text-h3 font-weight-light text-center pa-4">
        {{ $t('cart') | uppercase }}
      </p>
      <v-form
        id="loginForm"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="formSubmit()"
        class="v-form bg-white shadow-md rounded px-0 pr-3 pt-6 pb-8 mb-4"
      >
        <v-row>
          <v-col :cols="12" md="9" sm="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ $t('item') | uppercase }}</th>
                    <th class="text-right">{{ $t('color') | uppercase }}</th>
                    <th class="text-right">{{ $t('price') | uppercase }}</th>
                    <th class="text-right">{{ $t('qty') | uppercase }}</th>
                    <th class="text-right">{{ $t('total') | uppercase }}</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, idx) in getLineItems()" :key="idx">
                    <td>
                      <v-list-item key="1">
                        <v-list-item-avatar>
                          <router-link :to="val.product_url"><v-img :src="val.product_image"></v-img></router-link>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ val.product_title }}</v-list-item-title>
                          <v-list-item-subtitle>{{ $t('size') | camelcase }}: {{ val.size }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </td>
                    <td class="text-right">
                      {{ val.color }}
                    </td>
                    <td class="text-right">
                      {{ val.product_price | formatPrice }}
                    </td>
                    <td class="text-right">
                      <v-text-field
                        dense
                        class="rounded-0 float-right"
                        style="width: 40px; font-size: 14px"
                        v-model.number="val.quantity"
                        :rules="[qtyValidation]"
                        type="number"
                        min="1"
                        :max="val.max_qty"
                      ></v-text-field>
                    </td>
                    <td class="text-right">
                      {{ lineTotal(val) | formatPrice }}
                    </td>
                    <td class="text-center">
                      <a @click="deleteLineItem(val)">X</a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col :cols="12" md="3" sm="12" order="last" order-md="2" class="grey lighten-3">
            <p class="headline">{{ $t('summary') | camelcase }}</p>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{ $t('subtotal') | camelcase }}</td>
                    <td class="text-right" style="width: 120px">
                      {{ summary.subtotal | formatPrice }}
                    </td>
                  </tr>
                  <tr v-if="summary.shipping_total > 0">
                    <td>{{ $t('shippingFee') | camelcase }}</td>
                    <td class="text-right" style="width: 120px">
                      {{ summary.shipping_total | formatPrice }}
                    </td>
                  </tr>
                  <tr v-if="summary.total_tax > 0">
                    <td>{{ $t('tax') | camelcase }}</td>
                    <td class="text-right" style="width: 120px">
                      {{ summary.total_tax | formatPrice }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('total') | camelcase }}</td>
                    <td class="text-right" style="width: 120px">
                      <b>{{ summary.total | formatPrice }}</b>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="text-center" v-if="cartSize > 0">
              <v-btn class="primary white--text mt-5" outlined @click="createOrder" :disabled="disabled">
                {{ $t('confirmOrderBtn') | uppercase }}
                <Spinner :size="spinnerSize" :width="spinnerWidth" v-if="showSpinner" class="ml-2" />
              </v-btn>
            </div>
          </v-col>
          <v-col :cols="12" lg="9" sm="12" order="2" order-md="last" v-if="cartSize > 0">
            <v-row>
              <v-col :cols="12" md="6" sm="12" order="1" order-md="1">
                <p class="text-h5 font-weight-light text-center p-0 mb-0">
                  {{ $t('shipping_info') | uppercase }}
                </p>
              </v-col>
              <v-col :cols="12" md="6" sm="12" order="3" order-md="2">
                <p class="text-h5 font-weight-light text-center p-0 mb-0">
                  {{ $t('billing_info') | uppercase }}
                </p>
              </v-col>

              <v-col :cols="12" md="6" sm="12" order="2" order-md="3">
                <v-card class="px-5 py-5" style="height: 100%">
                  <v-row>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="shipping.first_name" :label="$t('first_name')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="shipping.last_name" :label="$t('last_name')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="12" class="py-0">
                      <v-text-field readonly v-model="shipping.address_1" :label="$t('address_1')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="shipping.city" :label="$t('city')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="shipping.postcode" :label="$t('postcode')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="shipping.state" :label="$t('state')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="shipping.country" :label="$t('country')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="12" class="py-0">
                      <v-text-field readonly v-model="shipping.company" :label="$t('company')"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col :cols="12" md="6" sm="12" order="4" order-md="4">
                <v-card class="px-5 py-5" style="height: 100%">
                  <v-row>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.first_name" :label="$t('first_name')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.last_name" :label="$t('last_name')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="12" class="py-0">
                      <v-text-field readonly v-model="billing.address_1" :label="$t('address_1')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.city" :label="$t('city')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.postcode" :label="$t('postcode')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.state" :label="$t('state')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.country" :label="$t('country')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="12" class="py-0">
                      <v-text-field readonly v-model="billing.company" :label="$t('company')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="partita_iva" :label="$t('piva') | camelcase"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="sdi" :label="$t('sdi') | uppercase"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.email" :label="$t('email')"></v-text-field>
                    </v-col>
                    <v-col :cols="12" sm="6" class="py-0">
                      <v-text-field readonly v-model="billing.phone" :label="$t('phone')"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col :cols="12" class="text-center d-none d-lg-block mt-2" order="last">
                <v-textarea v-model="note" name="input-7-1" :label="noteLabel" :hint="noteHint"></v-textarea>
              </v-col>
              <v-col :cols="12" class="text-center d-none d-lg-block mt-2" order="last">
                <v-btn class="primary white--text" outlined @click="createOrder" :disabled="disabled">
                  {{ $t('confirmOrderBtn') | uppercase }}
                  <Spinner :size="spinnerSize" :width="spinnerWidth" v-if="showSpinner" class="ml-2" />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <Conditions v-if="showConditions" />
    </v-container>
  </div>
</template>
<style>
  .v-text-field__details,
  .v-messages {
    min-height: 0;
  }
  .v-avatar {
    background-color: #fff;
  }
  .v-avatar.v-list-item__avatar a {
    display: block;
    max-width: 100%;
  }
</style>
<script>
  import axios from 'axios';
  import store from '@/store';
  import { sync } from 'vuex-pathify';
  import { orderBy } from 'lodash';
  import Dinero from 'dinero.js';
  import { captureException } from '@/utils/sentry';
  import { fetchWooCommerceData } from '../utils/common.js';

  export default {
    data: () => ({
      valid: true,
      showConditions: false,
      showSpinner: false,
      spinnerWidth: 2,
      spinnerSize: 20,
      disabled: false,
      note: '',
    }),
    mounted() {
      this.billing.country = 'Italia';
      this.shipping.country = 'Italia';
    },
    computed: {
      partita_iva: sync('misc/partita_iva'),
      sdi: sync('misc/sdi'),
      billing: sync('misc/billing'),
      shipping: sync('misc/shipping'),
      alert: sync('misc/alert'),
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
      baseUrl() {
        return store.get('misc/baseUrl');
      },
      noteLabel() {
        return this.$t('noteLabel');
      },
      noteHint() {
        return this.$t('noteHint');
      },
      items: sync('cart/lineItems'),
      cartSize() {
        return this.getLineItems().reduce((n, { quantity }) => n + quantity, 0);
      },
      summary() {
        let total;
        try {
          total = this.getLineItems().reduce((a, b) => a.add(this.lineTotal(b)), Dinero({ amount: 0, currency: 'EUR' }));
        } catch (e) {
          total = this.getLineItems().reduce((a, b) => a + this.lineTotal(b), 0);
        }
        return { subtotal: total, total: total };
      },
    },
    methods: {
      getLineItems() {
        let lines = this.items.filter((i) => i.user_id === store.get('misc/auth@id'));
        return orderBy(lines, ['product_title', 'size'], ['asc', 'asc']);
      },
      lineTotal(v) {
        let price = v.product_price;
        try {
          return price.multiply(v.quantity);
        } catch (e) {
          if (typeof price === 'object' && Object.keys(price).includes('amount')) {
            return new Dinero(price).multiply(v.quantity);
          } else {
            return price * v.quantity;
          }
        }
      },
      qtyValidation(v) {
        return (!isNaN(v) && v > 0 && !new RegExp('[.]+').test(v)) || this.$t('invalidQty');
      },
      async createOrder() {
        try {
          this.disabled = true;
          this.showSpinner = true;
          const lineItems = this.getLineItems().map((item) => {
            return {
              product_id: item.product_id,
              quantity: item.quantity,
              variation_id: item.variation_id,
            };
          });
          let errors = [];

          const ids = [...new Set(lineItems.reduce((a, c) => [...a, c.product_id], []))].map(id => {return id.split("_")[0]});         
          const getVariationResponses = await Promise.all(ids.map((id) => {
            return fetchWooCommerceData(this.authHeader, `${this.baseUrl}/wc/v3/products/${id}/variations`);
          }));

          getVariationResponses.forEach((data) => {
            let matched = this.getLineItems()
              .filter((line) => data.filter((variation) => line.variation_id === variation.id && line.quantity > variation.stock_quantity).length > 0)
              .map((line) => {
                let v = data.find((variation) => line.variation_id === variation.id);
                return {
                  product_title: line.product_title,
                  quantity: line.quantity,
                  stock_quantity: v.stock_quantity,
                  size: line.size,
                };
              });
            if (matched.length > 0) {
              errors.push(...matched);
            }
          });

          if (errors.length > 0) {
            this.disabled = false;
            let msg = errors
              .map((e) => {
                return `${e.product_title} (${e.size}) - max disponibilità: ${e.stock_quantity}, pezzi inseriti in carrello: ${e.quantity}`;
              })
              .join('<br />');

            store.set('misc/alert', {
              type: 'error',
              message: msg,
              show: true,
              autohide: false,
            });
          } else {
            var metaData = [];
            if (this.partita_iva.length > 0) {
              metaData.push({ key: 'partita_iva', value: this.partita_iva });
            }
            if (this.sdi.length > 0) {
              metaData.push({ key: 'sdi', value: this.sdi });
            }
            metaData.push({ key: 'origin', value: 'b2b' });

            let orderData = {
              billing: this.billing,
              shipping: this.shipping,
              set_paid: false,
              line_items: lineItems,
              meta_data: metaData,
              customer_id: store.get('misc/auth@id'),
            };

            const createOrderResp = await axios({
              method: 'post',
              url: `${this.baseUrl}/wc/v3/orders/`,
              data: orderData,
              headers: this.authHeader,
            });

            const orderId = createOrderResp.data.id;

            this.$router.push({
              name: 'ConfirmOrderListOrders',
              params: { id: orderId },
            });

            this.items = this.items.filter((v) => v.user_id !== store.get('misc/auth@id'));
            this.disabled = false;
            this.showSpinner = false;

            await axios({
              method: 'post',
              url: `${this.baseUrl}/wc/v3/orders/${orderId}`,
              headers: this.authHeader,
              data: {
                status: 'completed',
              },
            });

            if (this.note) {
              await axios({
                method: 'post',
                url: `${this.baseUrl}/wc/v3/orders/${orderId}/notes`,
                headers: this.authHeader,
                data: {
                  note: this.note,
                },
              });
            }
          }
        } catch (error) {
          captureException(error);
          this.alert = {
            message: this.$t('genericError'),
            type: 'error',
            autohide: false,
          };
          this.disabled = false;
          this.showSpinner = false;
        }
      },
      deleteLineItem(lineItem) {
        let idx = this.items.findIndex((i) => lineItem.product_id === i.product_id && lineItem.variation_id === i.variation_id);
        this.items.splice(idx, 1);
        store.set('cart/lineItems', this.items);
      },
      validate() {
        this.clearMsg();
        if (this.$refs.form.validate()) {
          this.showSpinner = true;
        }
      },
      clearMsg() {
        store.set('misc/alert', {});
      },
      setShippingState(val) {
        this.shipping.state = val;
      },
      setBillingState(val) {
        this.billing.state = val;
      },
    },
    components: {
      Spinner: () => import('@/views/components/mini-components/Spinner'),
    },
  };
</script>
