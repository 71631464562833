<template>
  <div class="mt-16">
    <v-container>
      <p class="text-h3 font-weight-light text-center pa-4">
        {{ $t('orderHist') | capitalize }}
      </p>
      <v-data-table
        :headers="headers"
        :items="orders"
        :items-per-page="itemsPerPage"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-text': $t('ordersPerPage'),
        }"
      >
        <template v-slot:[`item.detail`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="displayDetail(item.id)" slot="activator">mdi-eye</v-icon>
            </template>
            <span>{{ $t('view') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
  import axios from 'axios';
  import store from '@/store';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  dayjs.extend(utc);
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  dayjs.extend(customParseFormat);

  export default {
    mounted() {
      this.readDataFromAPI();
      if (this.id) {
        store.set('misc/alert', this.msg);
      }
    },
    computed: {
      msg() {
        return {
          message: this.$t('confirmOrder', { num: this.id }),
          type: 'info',
          autohide: true,
        };
      },
      headers() {
        return [
          {
            text: this.$options.filters.uppercase(this.$t('orderNumber')),
            value: 'number',
            sortable: false,
          },
          {
            text: this.$options.filters.uppercase(this.$t('date')),
            value: 'date_created',
            sortable: false,
          },
          {
            text: this.$options.filters.uppercase(this.$t('status')),
            value: 'status',
            sortable: false,
          },
          {
            text: this.$options.filters.uppercase(this.$t('total')),
            value: 'total',
            sortable: false,
          },
          {
            text: this.$options.filters.uppercase(this.$t('currency')),
            value: 'currency',
            sortable: false,
          },
          { text: '', value: 'detail', sortable: false },
        ];
      },
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
    },
    data: () => ({
      itemsPerPage: 15,
      orders: [],
      loading: true,
    }),
    methods: {
      displayDetail(orderId) {
        this.$router.push({ name: 'OrderDetail', params: { id: orderId } });
      },
      readDataFromAPI() {
        const fmt = 'DD/MM/YYYY HH:mm:ss';

        axios({
          method: 'get',
          url: `${store.get('misc/baseUrl')}/wc/v3/orders?customer=${store.get('misc/auth').id}`,
          params: {},
          headers: this.authHeader,
        }).then((response) => {
          this.orders = response.data.map(({ date_created, ...rest }) => ({
            ...rest,

            date_created: dayjs(date_created).utc(true).local().format(fmt),
          }));
          this.loading = false;
        });
      },
    },
    props: {
      id: {
        type: Number,
        required: false,
      },
    },
  };
</script>
<style>
  .v-text-field__details,
  .v-messages {
    min-height: 0;
  }
</style>
