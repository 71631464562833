<template>
  <div class="mt-16">
    <v-row justify="center" v-if="content.length === 0" class="pt-6">
      <v-col cols="1">
        <Spinner />
      </v-col>
    </v-row>
    <v-container v-else>
      <v-row>
        <v-col v-html="content"></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import axios from 'axios';
  import store from '@/store';
  import { sync } from 'vuex-pathify';
  import { captureException } from '@/utils/sentry';

  export default {
    mounted() {
      if (this.content.length === 0) {
        this.readDataFromAPI();
      }
    },
    computed: {
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
      baseUrl() {
        return store.get('misc/baseUrl');
      },

      content: sync('misc/info'),
    },
    data: () => ({
      pageId: 35128,
      loading: true,
    }),
    methods: {
      readDataFromAPI() {
        const pageReq = axios.get(`${this.baseUrl}/wp/v2/pages/${this.pageId}`, {
          headers: this.authHeader,
        });

        axios
          .all([pageReq])
          .then(
            axios.spread((...responses) => {
              this.loading = false;
              store.set('misc/info', responses[0].data.content.rendered);
            })
          )
          .catch((errors) => {
            captureException(errors);
          });
      },
    },
    components: {
      Spinner: () => import('@/views/components/mini-components/Spinner'),
    },
  };
</script>
<style>
  .v-text-field__details,
  .v-messages {
    min-height: 0;
  }
</style>
