import { make } from 'vuex-pathify';

const state = {
  proceed: false,
  auth: {},
  adminAuth: {},
  alert: {},
  billing: {},
  shipping: {},
  info: '',
  searchTerm: '',
  partita_iva: '',
  sdi: '',
  baseUrl: `${process.env.VUE_APP_BASE_URL}/wp-json`,
  datasetBaseUrl: 'https://b2b.mrtiggle.com/data/latest',
};

const mutations = make.mutations(state);

export default {
  namespaced: true,
  state,
  mutations,
};
