const SentryWebpackPlugin = require("@sentry/webpack-plugin");

module.exports = {
  css: {
    loaderOptions: {
      scss: {
        // Here we can use the newer SCSS flavor of Sass.
        // Note that there *is* a semicolon at the end of the below line
        data: `@import "@/scss/variables.scss";`,
      },
    },
  },
  publicPath: '/',
  chainWebpack: (config) => {
    config.plugin('html').tap((args) => {
      args[0].title = 'Mr. Tiggle - B2B';
      return args;
    });
  },
  configureWebpack: config => {
    if (process.env.NODE_ENV === 'production') {
      config.plugins.push(
        new SentryWebpackPlugin({
          org: "3w-srl",
          project: "b2b-bobux",
          include: "./dist",
          authToken: process.env.SENTRY_AUTH_TOKEN,
          // Optionally uncomment the line below to override automatic release name detection
          // release: process.env.RELEASE,
        })
      );
    }
  },
};
