import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import pathify from 'vuex-pathify';
import misc from '@/store/misc';
import cart from '@/store/cart';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 1,
    }),
  modules: ['cart'],
});
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['misc'],
});

export default new Vuex.Store({
  plugins: [pathify.plugin, vuexLocal.plugin, vuexCookie.plugin],
  //plugins: [pathify.plugin],
  modules: { misc, cart },
});
