<template>
  <div class="mt-16">
    <v-container>
      <v-row>
        <v-col :cols="12">
          <p class="text-h3 font-weight-light text-center pa-4">
            {{ $t('orderDetail') | capitalize }}
          </p>
          <v-data-table
            :headers="headers"
            :items="orderLines"
            :items-per-page="itemsPerPage"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              'items-per-page-text': $t('productsPerPage'),
            }"
          ></v-data-table>
        </v-col>
        <v-col :cols="12" class="text-center mt-2">
          <v-btn to="/orders" class="primary white--text ml-12">
            {{ $t('back') | uppercase }}
            <v-icon right>mdi-page-previous</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import axios from 'axios';
  import store from '@/store';

  export default {
    mounted() {
      this.readDataFromAPI();
    },
    computed: {
      headers() {
        return [
          { text: this.$options.filters.uppercase(this.$t('product')), value: 'parent_name', sortable: false },
          { text: this.$options.filters.uppercase(this.$t('size')), value: 'size', sortable: false },
          { text: this.$options.filters.uppercase(this.$t('color')), value: 'color', sortable: false },
          { text: this.$options.filters.uppercase(this.$t('qty')), value: 'quantity', sortable: false },
          { text: this.$options.filters.uppercase(this.$t('total')), value: 'total', sortable: false },
          { text: this.$options.filters.uppercase(this.$t('currency')), value: 'currency', sortable: false },
        ];
      },
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
    },
    data: () => ({
      loading: true,
      itemsPerPage: 15,
      orderLines: [],
    }),
    methods: {
      readDataFromAPI() {
        axios({
          method: 'get',
          url: `${store.get('misc/baseUrl')}/wc/v3/orders/${this.id}`,
          params: {},
          headers: this.authHeader,
        }).then((response) => {
          let currency = response.data.currency;
          this.orderLines = response.data.line_items.map((line) => {
            line.color = line.meta_data.find((md) => md.key.toLowerCase() === 'pa_color').display_value; 
            line.size = line.meta_data.find((md) => md.key.toLowerCase() === 'pa_size').display_value;
            line.currency = currency;
            return line;
          });
          this.loading = false;
        });
      },
    },
    props: {
      id: {
        type: Number,
        required: true,
      },
    },
  };
</script>
<style>
  .v-text-field__details,
  .v-messages {
    min-height: 0;
  }
</style>
