<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
  };
</script>
<style>
  * {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  .v-application {
    font-family: 'brandon-grotesque', serif;
    font-weight: 600;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  input:-internal-autofill-selected,
  input:-internal-autofill-selected:hover,
  input:-internal-autofill-selected:focus {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    font-weight: 600;
  }

  .v-application a {
    color: black !important;
  }

  .container {
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1000px;
    }
  }
  @media (min-width: 1264px) {
    .container {
      max-width: 1185px;
    }
  }
  @media (min-width: 1904px) {
    .container {
      max-width: 1185px;
    }
  }
</style>
