import axios from 'axios';

export async function fetchWooCommerceData (authHeader, url) {
  let allData = [];
  let page = 1;
  let totalPages = 1;

  try {
    while (page <= totalPages) {
      const p = {
        per_page: 100,
        page: page,
      };
      const response = await axios.get(url, {
        params: p,
        headers: authHeader,
      });

      allData = allData.concat(response.data);

      // Check if there are more pages
      totalPages = parseInt(response.headers['x-wp-totalpages']);

      page++;
    }

    return allData;
  } catch (error) {
    console.error('Error fetching WooCommerce data:', error);
    return [];
  }
}