import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/Login';
import Shop from '@/views/Shop';
import Product from '@/views/Product';
import Cart from '@/views/Cart';
import Layout from '@/views/Layout';
import Orders from '@/views/Orders';
import OrderDetail from '@/views/OrderDetail';
import Conditions from '@/views/Conditions';
import Info from '@/views/Info';

import { isLoggedIn, logoutUser } from '@/utils/auth';
import { publicPath } from '../../vue.config';
import store from '@/store';

Vue.use(VueRouter);

function logout(to, from, next) {
  logoutUser();
  next({ name: 'Login' });
}

const routes = [
  { path: '/logout', beforeEnter: logout },
  {
    path: '/',
    component: Layout,
    name: 'layout',
    children: [
      {
        path: 'conditions',
        component: Conditions,
        name: 'Conditions',
      },
      {
        path: 'info',
        component: Info,
        name: 'Info',
      },
      {
        path: 'shop',
        component: Shop,
        name: 'Shop',
      },
      {
        path: 'product/:id',
        component: Product,
        name: 'Product',
        props: true,
      },
      {
        path: 'order/:id',
        component: OrderDetail,
        name: 'OrderDetail',
        props: true,
      },
      {
        path: 'order/:id',
        component: Orders,
        name: 'ConfirmOrderListOrders',
        props: true,
      },
      {
        path: 'orders',
        component: Orders,
        name: 'ListOrders',
      },
      {
        path: 'cart',
        component: Cart,
        name: 'Cart',
      },
      {
        path: 'login',
        component: Login,
        name: 'Login',
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: publicPath,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let loggedIn = isLoggedIn();
  let isLogout = to.name == 'Logout' || to.path == '/logout';
  let agreedToProceed = store.get('misc/proceed');
  if (isLogout) {
    next();
  } else if (to.name == 'Login') {
    next();
  } else if (loggedIn && !agreedToProceed && to.name != 'Conditions') {
    next({ name: 'Conditions' });
  } else if (loggedIn) {
    next();
  } else {
    next({ name: 'Login' });
  }
});

export default router;
