<template>
  <v-app id="inspire">
    <AppBar />

    <router-view />

    <Footer />
  </v-app>
</template>
<script>
  export default {
    components: {
      AppBar: () => import('@/views/components/AppBar'),
      Footer: () => import('@/views/components/Footer'),
    },
  };
</script>
<style>
  .container {
    margin-bottom: 36px;
  }
  footer.v-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 36px;
    z-index: 9;
  }
</style>
