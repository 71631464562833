<template>
  <div id="product-page" class="mt-16">
    <v-row justify="center" v-if="loading" class="pt-6">
      <v-col cols="1">
        <Spinner />
      </v-col>
    </v-row>
    <v-container v-else>
      <div class="row">
        <div class="col-md-5 col-xs-12">
          <v-carousel>
            <v-carousel-item v-for="(img, idx) in product.images" :src="img" :key="idx"></v-carousel-item>
          </v-carousel>
        </div>
        <div class="col-md-7 col-xs-12">
          <div class="pl-md-6">
            <p class="subtitle-1 font-weight-thin" v-if="product.sku">{{ $t('code') | capitalize }}: {{ product.sku }}</p>
            <p class="display-1 mb-0 product-name" style="position: relative">
              {{ product.name }}
              <v-chip class="my-2 my-md-0" v-if="product.category">{{ product.category }}</v-chip>
            </p>
            <p class="subtitle-1 font-weight-thin">
              {{ product.color | uppercase }}
            </p>
            <v-card-actions class="pa-0" v-if="showSize">
              <p class="headline font-weight-light pt-3">
                {{ $t('b2bPrice') | uppercase }}:
                <template v-if="getB2BPrice().length == 2 && getB2BPrice()[0] !== getB2BPrice()[1]">{{ getB2BPrice()[0] }} - {{ getB2BPrice()[1] }}</template>
                <template v-else>
                  {{ getB2BPrice()[0] }}
                </template>
              </p>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-actions class="pa-0" v-if="showSize">
              <p class="subtitle-1 font-weight-thin">
                {{ $t('b2cPrice') | uppercase }}:
                <template v-if="getB2CPrice().length == 2 && getB2CPrice()[0] !== getB2CPrice()[1]">{{ getB2CPrice()[0] }} - {{ getB2CPrice()[1] }}</template>
                <template v-else>
                  {{ getB2CPrice()[0] }}
                </template>
              </p>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-form lazy-validation v-model="isFormValid">
              <v-row class="mt-2" align="end">
                <v-col cols="12">
                  <div class="d-none d-md-block">
                    <v-row>
                      <v-col cols="12" v-if="showSize">
                        <v-simple-table dense class="mb-10">
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td width="1" nowrap="" class="pl-0 pr-2">
                                  <strong>{{ $t('size') | uppercase }}</strong>
                                </td>
                                <td width="1" nowrap="" align="left" class="pl-0 pr-1" v-for="(variation, idx) in variations" :key="idx">
                                  {{ variation.size }}
                                </td>
                              </tr>
                              <tr class="blue lighten-4">
                                <td width="1" nowrap="" class="pl-0 pr-2">
                                  <strong>{{ $t('availability') | uppercase }}</strong>
                                </td>
                                <td width="1" nowrap="" align="left" class="pl-0 pr-1" v-for="(variation, idx) in variations" :key="idx">
                                  {{ variation.stock_quantity }}
                                </td>
                              </tr>
                              <tr>
                                <td width="1" nowrap="" valign="top" class="pl-0 pr-2 pt-2">
                                  <strong>{{ $t('qty') | uppercase }}</strong>
                                </td>
                                <td width="1" nowrap="" align="left" class="pl-0 pr-1" valign="top" v-for="(variation, idx) in variations" :key="idx">
                                  <v-text-field
                                    dense
                                    class="rounded-0"
                                    style="width: 40px; font-size: 14px"
                                    v-model.number="variation.qty"
                                    type="number"
                                    min="0"
                                    :max="variation.stock_quantity"
                                    :rules="[(v) => v <= variation.stock_quantity || $t('qtyTooHigh')]"
                                  ></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="d-md-none select-size-mobile">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="text-center px-4 py-2">
                          {{ $t('select_size') | uppercase }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-1">
                          <v-simple-table dense width="100%">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center px-0">
                                    {{ $t('size') | uppercase }}
                                  </th>
                                  <th class="text-center px-0" width="1">
                                    {{ $t('availability') | uppercase }}
                                  </th>
                                  <th class="text-center px-0">
                                    {{ $t('qty') | uppercase }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(variation, idx) in variations" :key="idx">
                                  <td nowrap="" align="center" class="px-0">
                                    {{ variation.size }}
                                  </td>
                                  <td nowrap="" align="center" class="px-0 blue lighten-4" width="1">
                                    {{ variation.stock_quantity }}
                                  </td>
                                  <td nowrap="" align="center" valign="top" class="px-0" variationign="top">
                                    <v-text-field
                                      dense
                                      class="rounded-0"
                                      style="width: 40px; font-size: 14px"
                                      v-model.number="variation.qty"
                                      type="number"
                                      min="0"
                                      :max="variation.stock_quantity"
                                      :rules="[(v) => v <= variation.stock_quantity + 1 || $t('qtyTooHigh')]"
                                    ></v-text-field>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>

                  <v-row class="mt-2" align="end">
                    <v-col cols="auto">
                      <v-btn class="primary white--text" @click="addToCart(product.id)" v-if="showSize" :disabled="!isSizeSelected() || !isFormValid">
                        <v-icon>mdi-cart</v-icon>
                        {{ $t('addToCart') | uppercase }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12 col-md-12">
          <v-tabs>
            <v-tab>{{ $t('descrTab') | uppercase }}</v-tab>
            <v-tab>{{ $t('matTab') | uppercase }}</v-tab>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin product-description-tab" v-html="product.description"></p>
            </v-tab-item>
            <v-tab-item>
              <p class="pt-10 subtitle-1 font-weight-thin product-material-tab" v-html="product.material"></p>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-container>
  </div>
</template>
<style>
  @media (min-width: 960px) {
    #product-page .product-name {
      padding-right: 150px;
    }
    #product-page .product-name .v-chip {
      max-width: 150px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  #product-page .product-description,
  #product-page .product-description p {
    margin-bottom: 0;
    line-height: 16px;
  }
  #product-page .product-description-tab,
  #product-page .product-description-tab p,
  #product-page .product-material-tab,
  #product-page .product-material-tab p {
    margin-bottom: 0;
    line-height: 20px;
  }
  #product-page .v-carousel__controls {
    background: transparent;
  }
  #product-page .v-carousel__controls__item.theme--dark.v-btn.v-btn--icon {
    color: #dedede;
  }
  #product-page .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  #product-page .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  #product-page .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  #product-page .v-data-table > .v-data-table__wrapper > table > tbody > tr > td input,
  #product-page .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td input,
  #product-page .v-data-table > .v-data-table__wrapper > table > thead > tr > td input {
    font-size: 10px;
    font-weight: 400;
  }

  #product-page .select-size-mobile .v-expansion-panel-header,
  #product-page .select-size-mobile .v-expansion-panel--active > .v-expansion-panel-header {
    font-size: 0.875rem;
  }
  #product-page .select-size-mobile .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>
<script>
  import axios from 'axios';
  import store from '@/store';
  import { sync } from 'vuex-pathify';
  import { orderBy } from 'lodash';
  import { unserialize } from 'php-serialize';
  import { fetchWooCommerceData } from '../utils/common.js';

  export default {
    data: () => ({
      product: {},
      variations: [],
      b2bPrice: [],
      b2cPrice: [],
      variationId: 0,
      loading: true,
      showSpinner: false,
      spinnerWidth: 2,
      spinnerSize: 20,
      disabled: false,
      isFormValid: true,
    }),
    components: {
      Spinner: () => import('@/views/components/mini-components/Spinner'),
    },
    computed: {
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
      baseUrl() {
        return store.get('misc/baseUrl');
      },
      showSize() {
        return Object.keys(this.variations).length;
      },
      cartItems: sync('cart/lineItems'),
      datasetBaseUrl: sync('misc/datasetBaseUrl'),
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    mounted() {
      store.set('misc/searchTerm', '');
      this.readDataFromAPI();
    },
    methods: {
      getMinMax(arr) {
        return [Math.min(...arr), Math.max(...arr)].filter((v) => v > 0).map((v) => this.$options.filters.formatPrice(this.$options.filters.toPrice(v)));
      },
      getB2BPrice() {
        return this.getMinMax(this.b2bPrice);
      },
      getB2CPrice() {
        return this.getMinMax(this.b2cPrice);
      },
      async readDataFromAPI() {
        const parts = this.id.split('_');
        const prodId = parts[0];
        const vColor = parts[1];

        const responses = await Promise.all([
          fetchWooCommerceData(this.authHeader, `${this.baseUrl}/wc/v3/products/${prodId}/variations`), // load fresh qty
          axios.get(`${this.datasetBaseUrl}/products.json`),
        ]);

        this.product = responses[1].data.find((i) => i.id === encodeURIComponent(this.id));
        this.product.category = this.product.badgeCategory?.name;

        let priceFilter = (md) => md.key === '_role_base_price_b2b';
        let staticDatasetVariations = this.product.variations;
        let data = responses[0].filter((i) => i.attributes.find((a) => a.id === 5).option === vColor);

        this.variations = orderBy(data, 'id', 'asc')
          .filter((variation) => variation.meta_data.find(priceFilter) || variation.price)
          .filter((variation) => variation.attributes.find((attr) => attr.id === 6))
          .map((variation) => {
            // need to subtract quantities already in cart for specific prod & variation
            let cartItem = this.cartItems.find((i) => i.product_id == this.product.id && i.variation_id == variation.id);
            if (cartItem) {
              variation.stock_quantity -= cartItem.quantity;
            }

            const staticVar = staticDatasetVariations.find((i) => i.id === variation.id);
            const b2cPrice = staticVar.b2cPrice;
            this.b2cPrice.push(b2cPrice);

            const customPrice = this.getCustomPrice(variation.meta_data.filter(priceFilter));
            const b2bPrice = customPrice.length === 0 ? variation.price : customPrice;
            this.b2bPrice.push(b2bPrice);
            return {
              id: variation.id,
              qty: 0,
              price: this.$options.filters.toPrice(b2bPrice),
              size: variation.attributes.find((attr) => attr.id === 6).option,
              stock_quantity: variation.stock_quantity,
              color:staticVar.color
            };
          });

        if (this.product.colors?.length > 1) {
          this.product.images = [];
          this.product.images.push(staticDatasetVariations.find((v) => v.id === this.variations[0].id).img );
        }
        //this.variations = orderBy(variations, ["size"], ["asc"])
        this.loading = false;
      },
      getCustomPrice(priceArr) {
        const serializedPrice = priceArr[0];
        return serializedPrice ? unserialize(serializedPrice.value).discount_value : '';
      },
      addToCart() {
        try {
          let itemsToAdd = this.variations
            .filter((variation) => variation.qty > 0 && variation.qty <= variation.stock_quantity)
            .map((variation) => {
              return {
                size: variation.size,
                max_qty: variation.stock_quantity,
                quantity: variation.qty,
                product_id: this.product.id,
                variation_id: variation.id,
                product_price: variation.price,
                product_url: `/product/${encodeURIComponent(this.id)}`,
                product_image: this.product.img,
                product_title: this.product.name,
                user_id: store.get('misc/auth@id'),
                color: variation.color
              };
            });
          this.variations.forEach((v) => {
            v.qty = 0;
          });

          itemsToAdd.forEach((item) => {
            let v = this.variations.filter((variation) => item.variation_id === variation.id)[0];
            v.stock_quantity -= item.quantity;
            v.qty = 0;

            var itemAlreadyInCart = this.cartItems.find((i) => item.product_id === i.product_id && item.variation_id === i.variation_id);
            if (itemAlreadyInCart) {
              itemAlreadyInCart.quantity += item.quantity;
            } else {
              this.cartItems.push(item);
            }
          });
          store.set('cart/lineItems', this.cartItems);
        } catch (error) {
          console.error(error);
        }
      },
      isSizeSelected() {
        return this.variations.filter((v) => v.qty <= v.stock_quantity).reduce((n, { qty }) => n + qty, 0) > 0;
      },
    },
  };
</script>
