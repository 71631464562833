<template>
  <v-container>
    <div class="pl-4 pr-4 row mt-16">
      <div class="col-12 col-md-6 offset-md-3">
        <v-card>
          <v-form
            id="loginForm"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="formSubmit()"
            class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <div class="mb-4">
              <v-text-field
                v-model="username"
                :rules="[(value) => !!value || this.$t('required')]"
                name="username"
                label="Username / E-mail"
                append-icon="mdi-username"
                autocomplete="off"
                @focus="clearMsg"
              ></v-text-field>
            </div>
            <div class="mb-6">
              <Password v-on:passPwd="setPwd" />
            </div>
            <div class="flex items-center justify-between">
              <v-btn class="mr-4" type="submit" color="green" @click="validate" :disabled="!valid || disabled">
                {{ $t('login') }}
                <Spinner :size="spinnerSize" :width="spinnerWidth" v-if="showSpinner" class="ml-2" />
              </v-btn>

              <v-btn @click="reset" :disabled="disabled">{{ $t('clear') }}</v-btn>
              <!-- <br /><br />
									<a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">{{ $t("passwordLost") | camelcase }}</a> -->
            </div>
          </v-form>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { loginUser, getUserInfo } from '@/utils/auth';
  import store from '@/store';

  export default {
    data: () => ({
      nextPage: 'Conditions',
      valid: true,
      username: '',
      password: '',
      showSpinner: false,
      spinnerWidth: 2,
      spinnerSize: 20,
      disabled: false,
    }),
    methods: {
      clearMsg() {
        store.set('misc/alert', {});
      },
      setPwd(val) {
        this.password = val;
      },
      validate() {
        this.clearMsg();
        if (this.$refs.form.validate()) {
          this.showSpinner = true;
        }
      },
      reset() {
        this.$refs.form.reset();
      },
      formSubmit() {
        this.disabled = true;
        loginUser(this.username, this.password)
          .then(() => {
            getUserInfo()
              .then(() => {
                this.$router.push({ name: this.nextPage }).catch((error) => {
                  console.info(error.message);
                });
              })
              .catch((error) => {
                if (error.data) {
                  store.set('misc/alert', { message: this.$t('errorCode_' + error.data.code) });
                } else {
                  store.set('misc/alert', { message: error });
                }
              })
              .finally(() => {
                this.showSpinner = false;
                this.disabled = false;
              });
          })
          .catch((error) => {
            this.showSpinner = false;
            this.disabled = false;

            if (error.data) {
              store.set('misc/alert', {
                message: this.$t('errorCode_' + error.data.data.status),
                type: 'error',
                autohide: false,
              });
            } else {
              store.set('misc/alert', { message: error, type: 'error', autohide: false });
            }
          });
      },
    },
    components: {
      Password: () => import('@/views/components/mini-components/Password'),
      Spinner: () => import('@/views/components/mini-components/Spinner'),
    },
  };
</script>
