<template>
  <div class="mt-16">
    <v-container>
      <v-row class="mt-4">
        <!-- ELENCO PRODOTTI -->
        <v-col cols="12" md="9" sm="12" order="2" order-md="1">
          <v-row>
            <!-- PRODOTTO SINGOLO -->
            <v-col cols="12" :key="pro.id" v-for="pro in products" class="mb-3 products-list">
              <v-lazy>
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="4" class="text-center">
                      <img :src="pro.img" class="active" />
                    </v-col>
                    <v-col cols="12" sm="8" class="font-weight-medium" style="position: relative">
                      <v-row class="mx-0">
                        <v-col cols="12">
                          <div>{{ $t('code') | capitalize }}: {{ pro.sku }}</div>
                        </v-col>
                      </v-row>
                      <v-row class="mx-0">
                        <v-col cols="12">
                          <div>
                            <v-chip small class="text-uppercase float-right" v-if="pro.badgeCategory">{{ pro.badgeCategory.name }}</v-chip>
                            <span class="font-weight-bold text-subtitle-2">{{ pro.name }}</span>
                            <div v-if="pro.colors.length > 1">{{ pro.color }}</div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-form>
                        <v-row class="mx-0">
                          <v-col cols="12">
                            <v-row class="mt-2" align="end">
                              <v-col cols="12" v-if="showSize(pro)">
                                <div class="d-none d-md-block">
                                  <v-simple-table dense class="mb-10">
                                    <template v-slot:default>
                                      <tbody>
                                        <tr>
                                          <td width="1" nowrap="" class="pl-0 pr-2">
                                            <strong class="text-caption">{{ $t('size') | uppercase }}</strong>
                                          </td>
                                          <td width="1" nowrap="" align="left" class="pl-0 pr-1" v-for="(variation, idx) in pro.variations" :key="idx">
                                            {{ variation.size }}
                                          </td>
                                        </tr>
                                        <tr class="blue lighten-4">
                                          <td width="1" nowrap="" class="pl-0 pr-2">
                                            <strong class="text-caption">{{ $t('availability') | uppercase }}</strong>
                                          </td>
                                          <td width="1" nowrap="" align="left" class="pl-0 pr-1" v-for="(variation, idx) in pro.variations" :key="idx">
                                            {{ variation.stock_quantity }}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="1" nowrap="" valign="top" class="pl-0 pr-2 pt-2">
                                            <strong class="text-caption">{{ $t('qty') | uppercase }}</strong>
                                          </td>
                                          <td
                                            width="1"
                                            nowrap=""
                                            align="left"
                                            valign="top"
                                            class="pl-0 pr-1"
                                            variationign="top"
                                            v-for="(variation, idx) in pro.variations"
                                            :key="idx"
                                          >
                                            <v-text-field
                                              dense
                                              class="rounded-0"
                                              style="width: 40px; font-size: 14px"
                                              v-model.number="variation.qty"
                                              type="number"
                                              min="0"
                                              :max="variation.stock_quantity"
                                              :rules="[(v) => v <= variation.stock_quantity + 1 || $t('qtyTooHigh')]"
                                            ></v-text-field>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </div>

                                <div class="d-md-none select-size-mobile">
                                  <v-expansion-panels class="mb-10">
                                    <v-expansion-panel>
                                      <v-expansion-panel-header class="text-center py-2">
                                        {{ $t('select_size') | uppercase }}
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content class="pa-1">
                                        <v-simple-table dense width="100%">
                                          <template v-slot:default>
                                            <thead>
                                              <tr>
                                                <th class="text-center px-0">{{ $t('size') | uppercase }}</th>
                                                <th class="text-center px-0" width="1">{{ $t('availability') | uppercase }}</th>
                                                <th class="text-center px-0">{{ $t('qty') | uppercase }}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="(variation, idx) in pro.variations" :key="idx">
                                                <td nowrap="" align="center" class="px-0">
                                                  {{ variation.size }}
                                                </td>
                                                <td nowrap="" align="center" class="px-0 blue lighten-4" width="1">
                                                  {{ variation.stock_quantity }}
                                                </td>
                                                <td nowrap="" align="center" valign="top" class="px-0" variationign="top">
                                                  <v-text-field
                                                    dense
                                                    class="rounded-0"
                                                    style="width: 40px; font-size: 14px"
                                                    v-model.number="variation.qty"
                                                    type="number"
                                                    min="0"
                                                    :max="variation.stock_quantity"
                                                    :rules="[(v) => v <= variation.stock_quantity || $t('qtyTooHigh')]"
                                                  ></v-text-field>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </template>
                                        </v-simple-table>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </div>
                              </v-col>
                            </v-row>
                            <div style="position: absolute; bottom: 12px; width: calc(100% - 48px)">
                              <v-row class="mt-2" align="end">
                                <v-col cols="4" md="auto">
                                  <v-btn small :to="`/product/${pro.id}`" color="white" class="success" outlined>
                                    {{ $t('viewBtn') }}
                                  </v-btn>
                                </v-col>
                                <v-col cols="8" md="auto" v-if="showSize(pro)" class="text-right text-md-left">
                                  <v-btn small class="primary white--text" @click="addToCart(pro.id)" :disabled="!isSizeSelected(pro)">
                                    <v-icon small>mdi-cart</v-icon>
                                    <span class="d-none d-sm-inline-block">{{ $t('addToCart') | uppercase }}</span>
                                    <span class="d-sm-none">{{ $t('addToCartMobile') | uppercase }}</span>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card>
              </v-lazy>
            </v-col>
            <!-- /PRODOTTO SINGOLO -->
          </v-row>
          <!-- /ELENCO PRODOTTI -->
          <v-row class="mt-3">
            <v-col cols="12" sm="8">
              <small>{{ $t('showing', { min: showMin, max: showMax, total: total }) }}</small>
            </v-col>
          </v-row>

          <!-- PAGINAZIONE -->
          <v-row>
            <v-col cols="12" class="mt-12">
              <v-pagination v-model="page" :length="pages" color="grey darken-4"></v-pagination>
            </v-col>
          </v-row>
          <!-- /PAGINAZIONE -->
        </v-col>
        <!-- FILTRI -->
        <v-col cols="12" md="3" sm="12" order="1" order-md="2" class="pt-0 filtersCol">
          <v-card outlined>
            <v-card-title>{{ $t('filters') | camelcase }}</v-card-title>
            <v-divider></v-divider>
            <v-select dense class="pa-3" :items="sizes.items" v-model="sizes.filter" :hint="$t('filterBySize') | camelcase" multiple persistent-hint />
            <v-divider></v-divider>
            <v-expansion-panels multiple accordion v-model="panels">
              <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('categories') | camelcase }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-treeview
                    :items="categories"
                    :selected-color="'#ececec'"
                    :open.sync="openIds"
                    activatable
                    on-icon="mdi-chevron-right"
                    off-icon="mdi-chevron-down"
                    open-on-click
                    dense
                  >
                    <template slot="label" slot-scope="{ item }">
                      <a :class="category == item.id ? 'grey lighten-2 px-1' : 'px-1'" @click="selectCategory(item)" style="font-size: 14px; font-weight: 100">
                        <v-icon small v-if="category == item.id" style="vertical-align: baseline; font-size: 14px">mdi-close</v-icon>
                        <span class="text-capitalize">{{ item.name.toLowerCase().trim() }}</span>
                      </a>
                    </template>
                  </v-treeview>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
        <!-- /FILTRI -->
      </v-row>
    </v-container>
  </div>
</template>
<style>
  .products-list .v-image,
  .products-list img {
    max-height: 95px;
  }
  .products-list .v-image.active,
  .products-list img.active {
    max-height: none;
    max-width: 100%;
  }

  .products-list .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .products-list .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .products-list .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .products-list .v-data-table > .v-data-table__wrapper > table > tbody > tr > td input,
  .products-list .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td input,
  .products-list .v-data-table > .v-data-table__wrapper > table > thead > tr > td input {
    font-size: 10px;
  }
  .products-list .select-size-mobile .v-expansion-panel-header,
  .products-list .select-size-mobile .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 34px;
    padding: 0 12.4444444444px;
    font-size: 0.75rem;
  }
  .products-list .select-size-mobile .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-treeview--dense .v-treeview-node__root {
    min-height: 0;
  }
  .v-treeview-node__level {
    width: 10px;
  }
  .filtersCol .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding: 0 3px;
  }
</style>
<script>
  import axios from 'axios';
  import store from '@/store';
  import Vue from 'vue';
  import { sync } from 'vuex-pathify';
  import { slice } from 'lodash';
  import fuzzysort from 'fuzzysort';

  export default {
    data: () => ({
      allProducts: [],
      categories: [],
      category: 0,
      itemsPerPage: 20,
      max: 120,
      min: 0,
      openIds: [],
      page: 1,
      pages: 1,
      products: [],
      range: [0, 120],
      showMax: 0,
      showMin: 0,
      sizes: { items: [], filter: [] },
      spinnerSize: 20,
      spinnerWidth: 2,
      total: 0,
      panels: [0],
    }),
    computed: {
      cartItems: sync('cart/lineItems'),
      datasetBaseUrl: sync('misc/datasetBaseUrl'),
      searchTerm: sync('misc/searchTerm'),
      authHeader() {
        return { Authorization: `Bearer ${store.get('misc/auth@token')}` };
      },
      baseUrl() {
        return store.get('misc/baseUrl');
      },
    },
    watch: {
      searchTerm() {
        this.page = 1;
        this.processData();
      },
      page() {
        this.processData();
      },
      'sizes.filter': function () {
        this.processData(false);
      },
    },
    methods: {
      sortSizes(a, b) {
        const sizes = ['NB', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL'];
        const aIdx = sizes.indexOf(a.toUpperCase());
        const bIdx = sizes.indexOf(b.toUpperCase());

        let val = 0;

        if (aIdx < 0 && bIdx < 0) {
          val = a > b ? 1 : -1;
        } else if (aIdx < 0 || bIdx < 0) {
          val = aIdx > bIdx ? 1 : -1;
        } else {
          val = aIdx - bIdx;
        }

        return val;
      },

      getSortedSizes(prods) {
        const key = 'size';

        const allSizes = prods
          .flatMap((i) =>
            i.variations.map((v) => {
              return { size: v.size, qty: v.stock_quantity };
            })
          )
          .map((item) => [item[key], item]);

        const sizes = [...new Map(allSizes).values()];
        return sizes.map((i) => i.size).sort((a, b) => this.sortSizes(a, b));
      },

      async loadProducts() {
        let response = await axios({
          method: 'get',
          url: `${this.datasetBaseUrl}/products.json`,
        });
        this.allProducts = response.data;
      },

      async loadCategories() {
        let response = await axios({
          method: 'get',
          url: `${this.datasetBaseUrl}/categories.json`,
        });
        this.categories = response.data;
      },

      processData(subtractQuantities = true) {
        let totalProds = [];
        if (this.searchTerm.length > 0) {
          totalProds = fuzzysort.go(this.searchTerm, this.allProducts, { allowTypo: true, keys: ['name', 'color', 'sku'] }).map((r) => r.obj);
        } else {
          totalProds = this.allProducts.filter((product) => (this.category > 0 ? product.categories.filter((p) => p.id === this.category).length > 0 : true));
          if (this.category > 0) {
            totalProds.forEach((i) => {
              i.totalQty = i.variations.reduce((partialSum, val) => partialSum + val.stock_quantity, 0);
            });
            totalProds.sort((a, b) => b.totalQty - a.totalQty);
          }
        }
        this.sizes.items = this.getSortedSizes(totalProds);

        if (subtractQuantities) {
          totalProds.forEach((product) => {
            product.variations.forEach((variation) => {
              // need to subtract quantities already in cart for specific prod & variation
              let cartItem = this.cartItems.find((i) => i.variation_id == variation.id);
              if (cartItem) {
                variation.stock_quantity -= cartItem.quantity;
              }
            });
          });
        }

        if (this.sizes.filter.length > 0) {
          totalProds = totalProds.filter((p) => p.variations.some((v) => this.sizes.filter.includes(v.size) && v.stock_quantity > 0));
        }

        let prods = slice(totalProds, (this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);

        this.products = prods;
        this.total = totalProds.length;
        this.pages = Math.ceil(this.total / this.itemsPerPage);
        this.showMin = this.page == 1 ? 1 : this.itemsPerPage * (this.page - 1) + 1;
        this.showMax = this.page == this.pages ? this.total : this.itemsPerPage * this.page;
      },
      selectCategory(category) {
        this.searchTerm = '';
        this.category === category.id ? (this.category = 0) : (this.category = category.id);
        this.page === 1 ? this.processData() : (this.page = 1);
      },
      showSize(product) {
        return product.variations.length > 0;
      },
      getProduct(id) {
        return this.products.filter((prod) => prod.id == id)[0];
      },
      isSizeSelected(product) {
        return product.variations.filter((v) => v.qty <= v.stock_quantity).reduce((n, { qty }) => n + qty, 0) > 0;
      },
      addToCart(id) {
        let product = this.getProduct(id);
        let itemsToAdd = product.variations
          .filter((variation) => variation.qty > 0 && variation.qty <= variation.stock_quantity)
          .map((variation) => {
            return {
              size: variation.size,
              max_qty: variation.stock_quantity,
              quantity: variation.qty,
              product_id: id,
              variation_id: variation.id,
              product_price: this.$options.filters.toPrice(variation.price),
              product_url: `/product/${id}`,
              product_image: product.img,
              product_title: product.name,
              user_id: store.get('misc/auth@id'),
              color: variation.color,
            };
          });
        product.variations.forEach((v) => {
          v.qty = 0;
        });

        itemsToAdd.forEach((item) => {
          let v = product.variations.find((variation) => item.variation_id === variation.id);
          v.stock_quantity -= item.quantity;

          var itemAlreadyInCart = this.cartItems.find((i) => item.product_id === i.product_id && item.variation_id === i.variation_id);
          if (itemAlreadyInCart) {
            itemAlreadyInCart.quantity += item.quantity;
          } else {
            this.cartItems.push(item);
          }
        });
        let idx = this.products.findIndex((x) => x.id === id);
        product.sizeSelected = false;
        Vue.set(this.products, idx, product);

        this.processData(false);
        store.set('cart/lineItems', this.cartItems);
      },
    },
    mounted() {
      this.loadProducts().then(() => {
        this.loadCategories();
        this.processData();
      });
    },
  };
</script>
