import decode from 'jwt-decode';
import axios from 'axios';
import store from '@/store';
import i18n from '@/i18n';
import { captureException } from '@/utils/sentry';

const baseUrl = store.get('misc/baseUrl');

export function loginUser(username, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/jwt-auth/v1/token`, {
        username: username,
        password: password,
      })
      .then((response) => {
        let d = response.data;
        store.set('misc/auth', {
          token: d.token,
          email: d.user_email,
          nicename: d.user_nicename,
          displayName: d.user_display_name,
          id: d.id,
        });
        resolve();
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function logoutUser() {
  clearAuthToken();
  store.set('misc/proceed', false);
}

export function clearAuthToken() {
  store.set('misc/auth', {});
  store.set('misc/adminAuth', {});
}

export function isLoggedIn() {
  let authToken = store.get('misc/auth@token');

  var loggedIn = false;
  if (authToken !== 'undefined') {
    loggedIn = !!authToken && !isTokenExpired(authToken);
  }
  return loggedIn;
}

export function getUserInfo() {
  if (isLoggedIn()) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}/wc/v3/customers/${store.get('misc/auth@id')}`, {
          headers: { Authorization: `Bearer ${store.get('misc/auth@token')}` },
        })
        .then((response) => {
          const roleAddressResp = response.data;
          if (roleAddressResp.role.includes('b2b')) {
            resolve();
          } else {
            reject(i18n.t('userNotAllowed'));
          }
          store.set('misc/billing', roleAddressResp.billing);
          store.set('misc/shipping', roleAddressResp.shipping);
          let piva = roleAddressResp.meta_data.filter((md) => md.key == 'partita_iva');
          store.set('misc/partita_iva', piva.length > 0 ? piva[0].value : '');
          let sdi = roleAddressResp.meta_data.filter((md) => md.key == 'sdi');
          store.set('misc/sdi', sdi.length > 0 ? sdi[0].value : '');
        })
        .catch((error) => {
          captureException(error);
          store.set('misc/auth', {});
          reject(i18n.t('genericError'));
        });
    });
  } else {
    return null;
  }
}

function getTokenExpirationDate(encodedToken) {
  let token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  let date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  let expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
